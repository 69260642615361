<template>
  <Header/>
  <LoginStatus />
  <div class="container is-max-desktop">
    <MessageError />

    <h1>Foretag en booking</h1>

    <p>
      En husstand kan have op til <strong>{{ global.user.upcommingBookingsMax }} kommende bookinger</strong>. Din husstand har p.t. {{ global.user.upcommingBookings.length }}.
    </p>
    <div class="progress-wrapper">
      <progress class="progress" :class="global.user.upcommingBookingsMax - global.user.upcommingBookings.length < 1 ? 'is-danger' : global.user.upcommingBookingsMax - global.user.upcommingBookings.length == 1 ? 'is-warning' : 'is-success'" :value="global.user.upcommingBookings.length" :max="global.user.upcommingBookingsMax">
        {{ global.user.upcommingBookings.length }} / {{ global.user.upcommingBookingsMax }}
      </progress>
      <p class="progress-value">{{ global.user.upcommingBookings.length }} / {{ global.user.upcommingBookingsMax }}</p>
    </div>
    <article class="message is-warning" v-if="global.user.upcommingBookingsMax <= global.user.upcommingBookings.length">
      <div class="message-body">
        <strong>Din husstands max er nået:</strong> Gå til <a href="/mine-bookinger">Mine bookinger</a>, og slet nogle af din husstands kommende bookinger, hvis du vil booke en ny nu.
      </div>
    </article>

    <div v-if="global.user.upcommingBookingsMax > global.user.upcommingBookings.length">
      <div class="form-section booking-period">
        <h2>Vælg tidsrum</h2>
        <div class="field">
          <div class="control">
            <label for="start">Afhentnings-tidspunkt</label>
            <input class="input" type="datetime-local" id="start" :min="booking.minStart"
              v-model="booking.start"
              @change="onDateFieldChange('booking.start')"
            >
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label for="end">Afleverings-tidspunkt</label>
            <input class="input" type="datetime-local" id="end" :min="booking.minEnd"
              v-model="booking.end"
              @change="onDateFieldChange('booking.end')"
            >
          </div>
        </div>

        <article v-if="Object.keys(booking.errorMessages).length" class="message is-warning">
          <div class="message-header">
            <p>Ret venligst</p>
          </div>
          <div class="message-body">
            <ul>
              <li v-for="(error, propertyName) in booking.errorMessages" :key="propertyName">{{ error }}</li>
            </ul>
          </div>
        </article>
      </div>

      <div class="form-section available-vehicles">
        <h2>Vælg køretøj</h2>
        <ul>
          <li v-for="(vehicle, vehicleId) in booking.availableVehicles" :key="vehicleId" :class="vehicle.available ? '' : 'not-available'">
            <label class="radio field">
              <input type="radio" name="vehicle" 
                :disabled="!vehicle.available"
                :checked="booking.selectedVehicle == vehicleId && vehicle.available"
                :value="[vehicleId] + ['|'] + [vehicle.available ? 'available' : 'unavailable']"
                @change="onVehicleChange($event)"
              >
              {{ vehicle.name }} ({{ vehicle.brand }}) 
              <span v-if="!vehicle.available"> - ikke ledig</span>
            </label>
          </li>
        </ul>
      </div>

      <div v-if="booking.selectedVehicle != 0 && !Object.keys(booking.errorMessages).length" class="form-section finalize-booking">
        <h2>Færdiggør booking</h2>
        <p>Din booking er reserveret: Bekræft den inden for {{ booking.reserveTime }} sekunder.</p>

        <div class="field">
          <div class="control">
            <label for="comment" class="sr-only">Evt. kommentar</label>
            <textarea class="textarea" id="comment" v-model="booking.comment" rows="3" placeholder="Evt. kommentar. Fx 'Jeg skal Aarhus og tager gerne andre med.'
  VIGTIGT: Ikke til beskeder til administrator!"></textarea>
          </div>
        </div>
        <div v-if="global.user.accessLvl == 1" class="field">
          <label class="checkbox">
            <input type="checkbox" v-model="booking.isAdminBooking">
            Administrator-booking (ingen beregning, husk beskrivelse)
          </label>
        </div>
        <button class="button" @click="bookingDelete(booking.id, booking.temp, '/kalender')">Fortryd</button> <button class="button is-success" @click="bookingSubmit()">Bekræft booking ({{ booking.reserveTime }})</button>
      </div>
  
      <article v-if="booking.reserveTimeEnded" class="message is-warning">
        <div class="message-header">
          <p>Reservation udløbet</p>
        </div>
        <div class="message-body">
          <p>Din reservation af køretøjet udløb.</p>
          <p>Vælg et køretøj igen, eller tryk 'Fortryd' for at komme tilbage til kalenderen.</p>
        </div>
      </article>
    </div>
  </div>

  <Footer/>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import LoginStatus from '@/components/LoginStatus.vue'
import MessageError from '@/components/MessageError.vue'

let minBookingTime = 60; // Minutes
let maxBookingTime = 60 * 24 * 10; // Minutes
let minBookingTimeSteps = 15; // Minutes
let bookingReserveTime = 60; // Seconds

export default defineComponent({
  setup() {
    const { global } = useComposition();

    return {
        global
    }
  },
  data() {
    return {
      nowIso: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString(),
      fieldTypes: {
        start: 'text'
      },
      booking: {
        id: 0,
        temp: '',
        start: '',
        minStart: '',
        end: '',
        startEndDiff: minBookingTime,
        comment: '',
        isAdminBooking: false,
        availableVehicles: [],
        selectedVehicle: 0,
        reserveTime: bookingReserveTime,
        errorMessages: {},
        reserveTimeEnded: false
      }
    }
  },
  components: {
    Header,
    Footer,
    LoginStatus,
    MessageError
  },
  created() {
      // Initalize start time value
      this.booking.start = this.ceilTime(this.nowIso.substr(0, 16), minBookingTimeSteps);
      this.booking.minStart = this.booking.start;

      this.booking.end = this.dateAdd(this.booking.start, this.booking.startEndDiff);
      this.booking.minEnd = this.booking.end;

      this.vehicleAvailability(this.booking.start, this.booking.end);
  },
  methods: {
    bookingCreate() {
      // If there is already a temp booking (then user has chosen a new vehicle), then delete it first
      if(this.booking.temp != '') {
        axios.get(process.env.VUE_APP_API_ROOT + '/booking.php?mode=delete&id=' + this.booking.id)
          .then(response => {

        })
      }
      
      // Create (temp) booking
      axios.get(process.env.VUE_APP_API_ROOT + '/booking.php?mode=createTemp&start=' + this.booking.start + '&end=' + this.booking.end + '&vehicle=' + this.booking.selectedVehicle)
        .then(response => {
            if(response.data.hasOwnProperty('error')) {
              this.global.error.error = true;
              this.global.error.code = response.data.errorCode;
              this.global.error.alias = response.data.alias;
            } else {
              this.booking.id = response.data.id;
              this.booking.temp = response.data.temp;
            }
      })
    },
    bookingSubmit() {
      if(this.booking.temp != '') {
        axios.get(process.env.VUE_APP_API_ROOT + '/booking.php?mode=submit&id=' + this.booking.id + '&temp=' + this.booking.temp + '&comment=' + this.booking.comment + '&isAdminBooking=' + (this.booking.isAdminBooking ? '1' : '0'))
          .then(response => {
              this.booking.temp = '';
              //location.href = '/booking/' + this.booking.id + '?new=1';
              
              this.$router.push('/booking/' + this.booking.id + '?new=1');

        })
      }
    },
    bookingDelete(id, temp, goToLocation = '') {
      axios.get(process.env.VUE_APP_API_ROOT + '/booking.php?mode=delete&id=' + id + '&temp=' + temp)
        .then(response => {
          if(goToLocation != '') {
            location.href = goToLocation;
            this.$router.push(goToLocation);
          }
        })
    },
    onDateFieldChange(field) {
      switch(field) {
        case 'booking.start':
          this.booking.start = this.ceilTime(this.booking.start, minBookingTimeSteps);
          this.booking.end = this.dateAdd(this.booking.start, this.booking.startEndDiff);
          if(this.booking.start < this.nowIso) {
            this.booking.errorMessages.startInPast = 'Starttidspunkt kan ikke være i fortiden.';
          } else {
            delete this.booking.errorMessages.startInPast;
          }
          break;

        case 'booking.end':
          this.booking.end = this.ceilTime(this.booking.end, minBookingTimeSteps);
          this.booking.startEndDiff = this.dateDiff(this.booking.start, this.booking.end);
          break;
      }

      // Re-check available vehicles for every change of start or end times.
      this.vehicleAvailability(this.booking.start, this.booking.end);
      
      // Check if booking is too short
      // else if: Check if booking is too long
      if(this.booking.startEndDiff < minBookingTime) {
        this.booking.errorMessages.startEndDiff = this.booking.startEndDiff < 0 ? 'Sluttidspunkt må ikke være før starttidspunkt.' : 'Mindste booking er 1 time.';
      } else if(this.booking.startEndDiff > maxBookingTime) {
        this.booking.errorMessages.startEndDiff = 'Maksimal booking er 10 døgn.';
      } else {
        delete this.booking.errorMessages.startEndDiff;
      }
    },
    onVehicleChange(e) {
      let data = e.target;
      let [vehicle, availability] = data.value.split('|');

      if(availability == 'available') {
        this.booking.selectedVehicle = parseInt(vehicle);
        this.bookingCreate();

        // Reservation timer
        var timeleft = bookingReserveTime;
        var downloadTimer = setInterval(function(scope) {
          if(timeleft <= 0){
            clearInterval(downloadTimer);

            // Delete temp booking, and inform user
            if(scope.booking.temp != '') {
              // scope.booking.temp is empty string when we have confirmed (submitted) booking and moved this.$router.push('/booking/' + this.booking.id + '?new=1');
              // With this check we ensure that temp bookings are only deleted
              scope.bookingDelete(scope.booking.id, scope.booking.temp);
            }
            scope.booking.reserveTime = bookingReserveTime;
            scope.booking.selectedVehicle = 0;
            scope.booking.reserveTimeEnded = true;
          }
          scope.booking.reserveTime = timeleft;
          timeleft -= 1;
        }, 1000, this);
      }
    },
    ceilTime(dateString, minutesToRound, field = '') {
      let [dateStr, timeStr] = dateString.split('T');
      let [hoursStr, minutesStr] = timeStr.split(':');
      let minutes = parseInt(minutesStr);

      let rounded = Math.ceil(minutes / minutesToRound) * minutesToRound;
      minutesStr = ('' + rounded % 60).padStart(2, '0');

      let output = dateStr + 'T' + hoursStr + ':' + minutesStr;
      if(field != '') {
        this.booking.start = output;
      } else {
        return output;
      } 
    },
    dateAdd(dateStr, minutes) {
      var dateIn = new Date(dateStr);
      var dateOut =  new Date(dateIn.getTime() + minutes*60000);

      return new Date(dateOut.getTime() - dateOut.getTimezoneOffset() * 60000).toISOString().substr(0, 16);
    }, 
    dateDiff(dateStartStr, dateEndStr) {
      let dateStart = new Date(dateStartStr);
      let dateEnd = new Date(dateEndStr);
      let diff = (dateEnd - dateStart);

      var diffDays = Math.floor(diff / 86400000); // days
      var diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
      var diffMins = Math.round(((diff % 86400000) % 3600000) / 60000);

      return diffDays * 1440 + diffHrs * 60 + diffMins;
    },
    vehicleAvailability(start, end) {
      axios.get(process.env.VUE_APP_API_ROOT + '/vehicle-availability.php?start=' + start + '&end=' + end)
        .then(response => {
            this.booking.availableVehicles = response.data;
            if(this.booking.availableVehicles[this.booking.selectedVehicle] != undefined) {
              let isAvailable = this.booking.availableVehicles[this.booking.selectedVehicle].available;
              if(!isAvailable) {
                this.booking.selectedVehicle = 0;
              }
            }
      })
    }
  },
  computed: {
    currentRoute() {
        return this.$route;
    }
  }
})
</script>
<template>
    <article v-if="global.error.error" class="global-error message is-warning ">
      <div class="message-header">
        <p>Ups! Der opstod en ukendt fejl</p>
      </div>
      <div class="message-body">
        <p>Hvis den fortsætter med at dukke op, så kontakt venligst administrator, og oplys fejlkode <strong>{{ global.error.code }}</strong> ('{{ global.error.alias }}').</p>
      </div>
    </article>
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

export default defineComponent({
  setup() {
    const { global } = useComposition();
    return {
        global,
        apiRoot: process.env.VUE_APP_API_ROOT
    }
  }
})
</script>
